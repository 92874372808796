import { useContext, useEffect, useState, type ReactElement } from 'react'
import classnames from 'classnames'
import { Alert, Box, Link as MuiLink, Typography } from '@mui/material'

import Header from '@/components/common/Header'
import css from './styles.module.css'
import SafeLoadingError from '../SafeLoadingError'
import Footer from '../Footer'
import SideDrawer from './SideDrawer'
import { useIsSidebarRoute } from '@/hooks/useIsSidebarRoute'
import { TxModalContext } from '@/components/tx-flow'
import BatchSidebar from '@/components/batch/BatchSidebar'
import { TemporaryDialog } from '@/components/common/TemporaryDialog'
import ExternalLink from '../ExternalLink'
import Link from 'next/link'
import { AppRoutes } from '@/config/routes'

const StickyBanner = () => (
  <Alert severity="warning">
    ALWAYS{' '}
    <ExternalLink href="https://help.safe.global/en/articles/276343-how-to-perform-basic-transactions-checks-on-safe-wallet">
      verify transactions
    </ExternalLink>{' '}
    that you are approving on your signer wallet. If you can&apos;t verify it, don&apos;t sign it.
  </Alert>
)

// Persistent Terms Banner component that's always visible
const PersistentTermsBanner = () => (
  <Box
    sx={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      backgroundColor: 'background.paper',
      p: 2,
      borderTop: '1px solid',
      borderColor: 'border.light',
      zIndex: 1200,
    }}
  >
    <Typography variant="body2" align="center">
      By using this application, you accept our{' '}
      <Link href={AppRoutes.terms} passHref>
        <MuiLink underline="always">Terms &amp; Conditions</MuiLink>
      </Link>{' '}
      and{' '}
      <Link href={AppRoutes.cookie} passHref>
        <MuiLink underline="always">Cookie policy</MuiLink>
      </Link>
    </Typography>
  </Box>
)

const PageLayout = ({ pathname, children }: { pathname: string; children: ReactElement }): ReactElement => {
  const [isSidebarRoute, isAnimated] = useIsSidebarRoute(pathname)
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(true)
  const [isBatchOpen, setBatchOpen] = useState<boolean>(false)
  const { setFullWidth } = useContext(TxModalContext)

  useEffect(() => {
    setFullWidth(!isSidebarOpen)
  }, [isSidebarOpen, setFullWidth])

  return (
    <>
      <header className={css.header}>
        <Header onMenuToggle={isSidebarRoute ? setSidebarOpen : undefined} onBatchToggle={setBatchOpen} />
      </header>

      {isSidebarRoute && <SideDrawer isOpen={isSidebarOpen} onToggle={setSidebarOpen} />}

      <div
        className={classnames(css.main, {
          [css.mainNoSidebar]: !isSidebarOpen || !isSidebarRoute,
          [css.mainAnimated]: isSidebarRoute && isAnimated,
        })}
      >
        <div className={css.content}>
          <div className={css.sticky}>
            <StickyBanner />
          </div>

          <SafeLoadingError>{children}</SafeLoadingError>
        </div>

        <BatchSidebar isOpen={isBatchOpen} onToggle={setBatchOpen} />

        <Footer />

        <TemporaryDialog />
      </div>

      {/* Add the persistent terms banner */}
      <PersistentTermsBanner />
    </>
  )
}

export default PageLayout
